import { useStaticQuery, graphql } from "gatsby"
import { SiteMetaDataQuery } from "../../graphql-types"
export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            siteUrl
            siteImage
            description
            author {
              name
              summary
            }
            social {
              twitter
            }
          }
        }
      }
    `
  ) as SiteMetaDataQuery
  return site?.siteMetadata
}
