/** @jsxImportSource theme-ui */

import { Link } from "gatsby"
import * as React from "react"
import {
  Box,
  Container,
  Flex,
  Text,
  Heading,
  jsx,
  NavLink,
  Grid,
} from "theme-ui"
import MenuLink from "./menu-link"
import RouteLink from "./route-link"
import RouteNavLink from "./route-nav-link"
import { FaGithub, FaLinkedin } from "react-icons/fa"

export interface TopNavProps {
  location: Location
  title: string
}
const TopNav: React.FC<TopNavProps> = ({ location, title }) => {
  return (
    <Box as="header" sx={{ bg: "muted", color: "text" }} px="m" pt="l" pb="m">
      <Container sx={{ pt: 4, pb: [2, 3], pl: 2 }}>
        <Flex sx={{ ml: 2 }}>
          <div>
            <Heading as="h1" sx={{ color: "text" }}>
              <RouteLink
                to="/"
                sx={{
                  variant: "links.siteName",
                }}
              >
                {title}
              </RouteLink>
            </Heading>
            <Heading as="h3" sx={{ color: "secondary" }}>
              Writing about things
            </Heading>
          </div>
        </Flex>
        <Flex
          as="nav"
          sx={{
            justifyContent: "space-between",
            mt: "m",
            ml: 4,
            mr: 4,
            fontSize: "l",
          }}
        >
          <Flex as="nav">
            <MenuLink path="/" title="Articles" />
            <MenuLink path="/bio" title="Bio" />
            <MenuLink path="/contact" title="Contact" />
          </Flex>
          <Flex as="nav">
            <NavLink
              href="https://www.linkedin.com/in/lianapigeot/"
              target="_blank"
              rel="noopener"
            >
              <FaLinkedin sx={{ mr: "s" }}></FaLinkedin>LinkedIn
            </NavLink>
            <NavLink
              href="https://github.com/liana-p"
              target="_blank"
              rel="noopener"
            >
              <FaGithub sx={{ mr: "s" }}></FaGithub>GitHub
            </NavLink>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default TopNav
