/** @jsxImportSource theme-ui */

import * as React from "react"
import { useForm, ValidationError } from "@formspree/react"
import { Label, Input, Textarea, Box, Button } from "theme-ui"
import { Helmet } from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha"

const ContactForm: React.FC<any> = () => {
  const [state, handleSubmit] = useForm("xdoypgoy")
  if (state.succeeded) {
    return <p>Thanks for your message, I will go back to you shortly!</p>
  }
  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Label htmlFor="name">Name</Label>
      <Input
        id="name"
        type="text"
        name="name"
        placeholder="Your Name"
        required
      />
      <ValidationError prefix="Name" field="name" errors={state.errors} />
      <Label htmlFor="email">Email Address</Label>
      <Input
        id="email"
        type="email"
        name="email"
        placeholder="email@example.com"
        required
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <Label htmlFor="message">Your message</Label>
      <Textarea id="message" name="message" placeholder="Hi, " required />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <ReCAPTCHA sitekey="6LdFSQQcAAAAAJKQTWSmcRwGuWswKBjqbr_9202A" />
      <Button
        type="submit"
        sx={{ variant: "buttons.form", my: "m", cursor: "pointer" }}
        disabled={state.submitting}
      >
        Submit
      </Button>
    </Box>
  )
}
export default ContactForm
